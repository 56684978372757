@import url('https://fonts.googleapis.com/css2?family=Sen&display=swap');

body {
  margin: 0;
  font-family: Sen !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ui, .item {
  font-family:  Sen !important;
}

.ui .text .container{
  font-family:  Sen !important;
}

.ui .field:input{
  font-family: Sen !important;
}

.ui .statistic .value{
  font-family:  Sen !important;

}

.ui .list, .ui .list .header {
  font-family:  Sen !important;
  font-size: 14px;
}

.ui .label {
  font-family:  Sen !important;

}

.ui .card .header{
  font-family: Sen !important;
}

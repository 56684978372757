@import url('https://fonts.googleapis.com/css2?family=Sen&display=swap');

.ui .header .item  .list  {
  font-family:Sen !important;
}

.ui input{
  font-family: sen !important;
}

.open-grids{
  margin-left:5px !important;
}

.subject{
  font-weight: 800;
} 

.loader-div{
  display: flex;
  flex:1;
  width:50%;
  align-items: center;
  height: 70vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color:rgba(76, 131, 212,0.4);
}

.cLabel{
  display: flex !important;
  flex:1 !important;
  justify-content: center !important;
  align-items: center !important;
  text-align: center !important;
  align-content: center !important;
  padding:20px;
}

.wall{
  min-height: 100vh !important;
}

.school-name{
  border-right:1px solid inherit !important; 
  padding-left:5px !important;
}

.login-side{
  display: flex !important;
  flex: 1 !important;
  flex-direction: column;
  height: 100vh !important;
  align-items: center !important;
  background-color:rgba(76, 131, 212,0.4);
  justify-content: center !important;
  background-image: url(./staff-ui/images/cl-0.jpg);
  background-blend-mode: multiply !important;
  background-size:cover !important;
  background-position: center !important;
  background-repeat:no-repeat !important;
}

.sign-session{
  color:red !important;
}


.form-side{
  display:grid  !important; 
  place-items: center !important;
}

.relaxed{
  margin:20px 0;
}

.main-form{
  display:flex !important;
  align-items:center !important;
  flex-direction:column !important;
  width:80% !important;
 
}
.hol{
  display: none !important;
}



.initials > div > .tt {
  padding: 15px;
  font-weight: bolder !important;
  font-size:170px !important;
}

.hide-on-desktop{
  display:none !important;
}

@media screen and (max-width:700px){
  .hom{
    display:none !important;
  }

  .hide-on-mobile{
    display:none !important;
  }

  .hide-on-desktop{
    display:inline-block !important;
  }
  

  .hol{
    display: inline-flex !important;
  }
}